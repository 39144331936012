import React from "react";

import SiteLayout from '../components/site_layout';
import SEO from "../components/seo";

import GoodsComponent from '../components/wx_store/goods';

const GoodsManagePage = () => (
  <SiteLayout>
    <SEO title="WX Goods" />
    <GoodsComponent></GoodsComponent>
  </SiteLayout>
)

export default GoodsManagePage;
